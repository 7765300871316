import React from "react";
import preval from "preval.macro";
import dayjs from "dayjs";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { BsShieldLock } from "react-icons/bs";
import packageJson from "../../package.json";

const buildTimestamp = preval`module.exports = new Date().getTime();`;

const getDateString = () => {
    const lastUpdateMoment = dayjs.unix(buildTimestamp / 1000);
    const formattedDate = lastUpdateMoment.format("dddd, MMMM D, YYYY h:mm A");

    return formattedDate;
};
const homeStyle = {
    // textAlign: "center",
    // listStylePosition: "inside",
    // height: "100vh",
    margin: "1em 25% 0 25%",
};

const headerStyle = {
    textAlign: "center",
};

const About = ({ user }) => {
    return (
        <div style={homeStyle}>
            <div style={headerStyle}>
                <h3>Welcome {user.attributes.name.split(" ").shift()}!</h3>
                <p>
                    This website is intended to provide handy information for the Fedorovsky family <FaRegFaceSmileBeam />
                </p>
            </div>
            <div>
                <h5>Some points:</h5>
                <ul>
                    <li>
                        This website is private <BsShieldLock />
                    </li>
                    <ul>
                        <li>Access to this website is restricted to a specific set of email addresses. If you wish to add someone please let me (David) know</li>
                    </ul>
                    <li>This website is best viewed on a large screen (computer/laptop/tablet)</li>
                    <ul>
                        <li>Mobile support will be added eventually</li>
                    </ul>
                    <li>
                        {"You're currently viewing version v"}
                        {packageJson.version}
                        {", built on "}
                        {getDateString()}
                    </li>
                    {/* <li>This website is built using reactjs, with backend components hosted on AWS</li>
                    <ul>
                        <li>The code is on a private Github repo, message David if you want to see it (or add new features yourself)</li>
                    </ul> */}
                </ul>
            </div>
        </div>
    );
};

export default About;
